import React from 'react';
import { connect } from 'react-redux';
import { openModal, modalInfo } from '../../state/state-modal';
import FocusTrap from 'focus-trap-react';
import anime from 'animejs';
import TransitionLink from 'gatsby-plugin-transition-link';
import Particles from 'react-particles-js';
import particlesJson from '../../helpers/particles.json'
import About from '../../svg/about-icon.svg'
import Events from '../../svg/events-icon.svg'
import Gallery from '../../svg/gallery-icon.svg'
import Services from '../../svg/services-icon.svg'
import Contact from '../../svg/contact-icon.svg'
import Home from '../../svg/home-icon.svg'
import movement from './../../helpers/movement';
import { setMenuOpenItem } from '../../state/state-site';

class Nav extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      background: false,
      currentPage: null
    };
  }

  componentDidMount () {
    setTimeout(() => this.setState({background: true}), 300);
  }

  handleBugerClick = () => {
    if (this.props.openMenu) {
      this.props.dispatchCloseMenu()
      this.props.startTracking();
    } else {
      movement.removeListeners();
      this.props.setMenuOpenItem(this.props.currentPage);
      this.props.dispatchOpenMenu()
    }
  }

  handleKeydown = (e) => {
    switch (e.keyCode) {
      case 13: // enter
        this.handleBugerClick();
        break;
    }
  }
  
  linkOutClick = () => {
    setTimeout(() => {
      this.props.dispatchCloseMenu()
    }, 100);
  }


  render () {
    let props = this.props;
    
    return (
      <FocusTrap
        active={this.props.openMenu ? true : false}
      >
        <nav 
          className={`nav ${this.props.openMenu ? 'active' : ''}`}
        >
          <div 
            className={this.props.openMenu ? "nav-burger active" : "nav-burger"}
            tabIndex="0" 
            aria-label="Menu" 
            role="button" 
            aria-controls="navigation"
            onClick={this.handleBugerClick}
            onKeyDown={e => this.handleKeydown(e)}
          >
            <div className="nav-burger__bar-1"></div>
            <div className="nav-burger__bar-2"></div>
            <div className="nav-burger__bar-3"></div>
            <div className="nav-burger__text">{this.props.openMenu ? "Close" : "Menu"}</div>
          </div>
          <div className={`nav-background`}>
          </div>
          <div className={`nav-content`}>
            {this.props.openMenu &&
              <Particles
                className="nav-particles"
              />
            }
            <TransitionLink
              to={`/`}
              onClick={this.linkOutClick}
              tabIndex={this.props.openMenu ? '0' : '-1'}
              className={`nav-content__link ${this.props.menuOpenItem == 'home' ? 'active' : ''}`}
            >
              <div className="nav-content__link-icon">
                <Home/>
              </div>
              <span className="nav-content__link-text">home</span>
            </TransitionLink>
            <TransitionLink
              to={`/about`}
              onClick={this.linkOutClick}
              tabIndex={this.props.openMenu ? '0' : '-1'}
              className={`nav-content__link ${this.props.menuOpenItem == 'about' ? 'active' : ''}`}
            >
              <div className="nav-content__link-icon">
                <About/>
              </div>
              <span className="nav-content__link-text">about</span>
            </TransitionLink>
            <TransitionLink
              to={`/events`}
              onClick={this.linkOutClick}
              tabIndex={this.props.openMenu ? '0' : '-1'}
              className={`nav-content__link ${this.props.menuOpenItem == 'events' ? 'active' : ''}`}
            >
              <div className="nav-content__link-icon">
                <Events/>
              </div>
              <span className="nav-content__link-text">events</span>
            </TransitionLink>
            <TransitionLink
              to={`/gallery`}
              onClick={this.linkOutClick}
              tabIndex={this.props.openMenu ? '0' : '-1'}
              className={`nav-content__link ${this.props.menuOpenItem == 'gallery' ? 'active' : ''}`}
            >
              <div className="nav-content__link-icon">
                <Gallery/>
              </div>
              <span className="nav-content__link-text">gallery</span>
            </TransitionLink>
            <TransitionLink
              to={`/collaboration`}
              onClick={this.linkOutClick}
              tabIndex={this.props.openMenu ? '0' : '-1'}
              className={`nav-content__link ${this.props.menuOpenItem == 'services' ? 'active' : ''}`}
            >
              <div className="nav-content__link-icon">
                <Services/>
              </div>
              <span className="nav-content__link-text">collab</span>
            </TransitionLink>
            <TransitionLink
              to={`/contact`}
              onClick={this.linkOutClick}
              tabIndex={this.props.openMenu ? '0' : '-1'}
              className={`nav-content__link ${this.props.menuOpenItem == 'contact' ? 'active' : ''}`}
            >
              <div className="nav-content__link-icon">
                <Contact/>
              </div>
              <span className="nav-content__link-text">contact</span>
            </TransitionLink>

          </div>
        </nav>
      </FocusTrap>
    )
  }
}

Nav.defaultProps = {
};

export default connect(  
  state => ({ 
    modal: state.modalState,
    menuOpenItem: state.siteState.menuOpenItem
  }),
  dispatch => ({ 
    openModal: () => dispatch(openModal()),
    setMenuOpenItem: item => dispatch(setMenuOpenItem(item)),
  }),
)(Nav);