import React from "react"
import "../styles/main.scss"
import { connect } from 'react-redux';
import { closeModal } from '../state/state-modal';
import movement from "../helpers/movement";
import { updateCursor, updateWindow } from "../state/state-movement";
import Nav from "./navigation/nav";
import { openMenu, closeMenu } from './../state/state-site';

class Layout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      closeModal: false
    };
  }

  componentDidMount () {
    this.startCursorTracking();
  }

  startCursorTracking = () => {
    movement.startupTasks(this.props.window, this.props.updateWindow, this.props.updateCursor);
  }

  render () {
    let props = this.props;
    let childProps = props.childProps;

    return (
      <>
        {this.props.class !== 'construction' ? 
        <Nav
          dispatchOpenMenu={props.openMenu}
          dispatchCloseMenu={props.closeMenu}
          currentPage={this.props.class}
          openMenu={props.menuOpen}
          tabIndex='0'
          startTracking={this.startCursorTracking}
        />
        : ''}
        {/* MAIN */}
        <main className={props.class + ' above-ground'}>
          {props.children}
        </main>
      </>
    )
  }
}

// Set default props
Layout.defaultProps = {
  childProps: {
    transitionStatus: undefined,
    current: {
      state: {
        direction: undefined
      }
    },
    siblings: ['blah','blah']
  }
};


export default connect(  
  state => ({ 
    window: state.movementState.windowDimensions,
    depth: state.movementState.depthLevels,
    menuOpen: state.siteState.menuOpen,
  }),
  dispatch => ({ 
    updateCursor: cursorObj => dispatch(updateCursor(cursorObj)),
    updateWindow: windowObj => dispatch(updateWindow(windowObj)),
    openMenu: () => dispatch(openMenu()),
    closeMenu: () => dispatch(closeMenu()),
  }),
)(Layout);