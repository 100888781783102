import {isMobile, isTablet} from 'react-device-detect';
import SwipeListener from 'swipe-listener';

const movement = {
  startupTasks: (windowProps, updateWindow, updateCursor) => {   
    setTimeout(() => {
      if (!windowProps.set) updateWindow()

      if (!windowProps.cursor.capture || window.movementEvents == false) {
        if (isMobile || isTablet) {
          window.movementEvents = true;
          movement.gyroscopeCapture(updateCursor)
          movement.mobileSpecific()
        } else {
          window.movementEvents = true;
          movement.cursorCapture(updateCursor)
        }
      }
    }, 500);
  },
  gyroscopeCapture: (dispatch) => {
    window.addEventListener('deviceorientation', movement.gyroscopeCaptureEvent = (event) => {
      var x = 0;
      var y = 0;

      if (event.gamma !== null) {        
        if (window.innerHeight > window.innerWidth) {
          x = event.gamma;
          y = event.beta;
        } else {
          x = event.beta;
          y = event.gamma;
        }
      }


      // Because we don't want to have the device upside down
      // We constrain the x value to the range [-90,90]
      if (x >  90) { x =  90};
      if (x < -90) { x = -90};

      if (x !== 0) {
        dispatch({
          y: y * 5,
          x: x * 6,
          gyro: true,
        })
      }
    });
  },
  cursorCapture: (dispatch) => {
    window.addEventListener("mousemove", movement.cursorCaptureEvent = (e) => {
      dispatch({
        y: event.clientY,
        x: event.clientX,
      })
    }, false);
  },
  scrollCapture: () => {
    var mousewheelevt=(/Firefox/i.test(navigator.userAgent))? "DOMMouseScroll" : "mousewheel" //FF doesn't recognize mousewheel as of FF3.x
 
    document.addEventListener(mousewheelevt, (e) => {
      let delta = e.deltaY || e.detail;
      let upDown = delta > 0 ? 'down' : 'up';
      console.log(upDown)
      switch (upDown) {
        case 'up':
          // Add scroll
          break;
        case 'down':
          // Subtract scroll
          break;
        default:
          break;
      }

    }, false)
  },
  mobileSpecific: () => {

    // Resize changes
    window.onresize = function(){
      document.body.height = window.innerHeight;
    }
    window.onresize();

    // Try to lock orientation to portrait

    // Setup swiping
    movement.swipeSetup();
  },
  removeListeners: () => {
    window.removeEventListener('mousemove', movement.cursorCaptureEvent);
    window.removeEventListener('deviceorientation', movement.gyroscopeCaptureEvent);
    window.movementEvents = false;
  },
  swipeSetup: () => {
    var container = document.querySelector('#___gatsby');
    var listener = SwipeListener(container);
    container.addEventListener('swipe', function (e) {
      var directions = e.detail.directions;
      var x = e.detail.x;
      var y = e.detail.y;

      
    
      if (directions.left) {
        // Note: reversing the swipes feels better
        let rightButton = document.querySelector('#right-button');
        movement.simulateClick(rightButton);
      }
    
      if (directions.right) {
        // Note: reversing the swipes feels better
        let leftButton = document.querySelector('#left-button');
        movement.simulateClick(leftButton);
      }
    
      if (directions.top) {}
    
      if (directions.bottom) {}
    
    });
  },
  simulateClick: (elem) => {
    // Create our event (with options)
    var evt = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    });
    // If cancelled, don't dispatch our event
    var canceled = !elem.dispatchEvent(evt);
  },
}

export default movement;